import { createSelector } from '@ngrx/store';
import { mrraFeatureSelector } from '../reducers/mrra.reducer';

const mrraSelectors = createSelector(
  mrraFeatureSelector,
  (state) => state
);

const getGridFilter = createSelector(
  mrraFeatureSelector,
  (state) => state.gridFilters
);

export const MrraSelectors = { getGridFilter };
