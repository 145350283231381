import { createAction, props } from '@ngrx/store';
import { countriesCdEnum } from '@shared/enums/countries-cd.enum';
import { ErrorModel } from '@shared/models/error';
import {
  CreatedRateQuote,
  RateQuoteCallGetListAttributtes,
  RateQuoteSearchCustomerSpecificForApi,
  RateQuoteSearchCustomerSpecificMadCdForApi,
  RateQuoteSearchTabsLabel,
  RecalculateRateQuoteTypes
} from '@shared/models/rate-quote';
import { SpecialCustomerAcsModel } from '@shared/models/special-customer-acs.model';
import { CustomerMatch, GetCustomerLocationQuery, GetCustomerLocationResp } from '@xpo-ltl-2.0/sdk-customer';
import {
  CreateInternationalRateQuoteResp,
  CreateInternationalRateQuoteRqst, CreateRateQuoteResp, CreateRateQuoteRqst,
  CreateSpotQuoteResp,
  CreateSpotQuoteRqst,
  GetRateQuoteFileResp,
  GetRateQuoteForShipmentResp,
  GetRateQuoteResp,
  ListRateQuotesResp
} from '@xpo-ltl-2.0/sdk-rating';
import { DataOptions } from '@xpo-ltl/data-api';
import { AddQuoteAccesorialsType } from 'app/rate-quote/add-rate-quote/shared/form-utils/add-quote-form.config';

export abstract class RateQuoteActions {
  static clearRateQuoteStore = createAction('[RateQuoteActions] Clear Rate Quote Store');

  static getRateQuoteList = createAction(
    '[RateQuoteActions] Get Rate Quote List',
    props<{ rateQuoteAttributtes: RateQuoteCallGetListAttributtes }>()
  );
  static getRateQuoteListSuccess = createAction(
    '[RateQuoteActions] Get Rate Quote List Success',
    props<{ rateQuoteList: ListRateQuotesResp; observableId: string }>()
  );
  static getRateQuoteListFail = createAction(
    '[RateQuoteActions] Get Rate Quote List Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getRateQuotePdf = createAction(
    '[RateQuoteActions] Get Rate Quote Pdf',
    props<{ cnfmNbr: string; serviceDataOptions: DataOptions, retryApiCall: boolean }>()
  );

  static getRateQuotePdfSuccess = createAction(
    '[RateQuoteActions] Get Rate Quote Pdf Success',
    props<{ rateQuoteFileResp: GetRateQuoteFileResp }>()
  );

  static getRateQuotePdfFail = createAction(
    '[RateQuoteActions] Get Rate Quote Pdf Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static createRateQuote = createAction(
    '[RateQuoteActions] Create a new Rate Quote',
    props<{ request: CreateRateQuoteRqst }>()
  );
  static createRateQuoteSucess = createAction(
    '[RateQuoteActions] Create a new Rate Quote Sucess',
    props<{ createRateQuoteResp: CreateRateQuoteResp }>()
  );
  static createRateQuoteFail = createAction(
    '[RateQuoteActions] Create a new Rate Quote Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static createRateQuoteUpsell = createAction(
    '[RateQuoteActions] Create a new Rate Quote Upsell',
    props<{ request: CreateRateQuoteRqst, recalculateRateQuoteTypes?: RecalculateRateQuoteTypes, dataOptions?: DataOptions }>()
  );
  static createRateQuoteUpsellSucess = createAction(
    '[RateQuoteActions] Create a new Rate Quote Upsell Sucess',
    props<{ createdRateQuote: CreatedRateQuote }>()
  );
  static createRateQuoteUpsellFail = createAction(
    '[RateQuoteActions] Create a new Rate Quote Upsell Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static createSpotQuote = createAction(
    '[RateQuoteActions] Create a new Spot Quote',
    props<{ request: CreateSpotQuoteRqst }>()
  );
  static createSpotQuoteSuccess = createAction(
    '[RateQuoteActions] Create a new Spot Quote Success',
    props<{ createSpotQuoteResp: CreateSpotQuoteResp }>()
  );
  static createSpotQuoteFail = createAction(
    '[RateQuoteActions] Create a new Spot Quote Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static addNewSpotQuoteOptionEnabled = createAction(
    '[RateQuoteActions] Enabled the add a new spot quote option',
    props<{ addNewSpotQuoteOptionEnabled: boolean }>()
  );

  static createInternationalRateQuote = createAction(
    '[RateQuoteActions] Create a new International Rate Quote',
    props<{ request: CreateInternationalRateQuoteRqst }>()
  );
  static createInternationalRateQuoteSucess = createAction(
    '[RateQuoteActions] Create a new International Rate Quote Sucess',
    props<{ createInternationalRateQuoteResp: CreateInternationalRateQuoteResp }>()
  );
  static createInternationalRateQuoteFail = createAction(
    '[RateQuoteActions] Create a new International Rate Quote Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static clearRateQuoteList = createAction('[RateQuoteActions] Clear Rate Quote List');

  static getCustomersFiltered = createAction(
    '[RateQuoteActions] Get Customers filtered',
    props<{ filterData: string }>()
  );
  static getCustomersFilteredSuccess = createAction(
    '[RateQuoteActions] Get Customers filtered Success',
    props<{ customers: CustomerMatch[] }>()
  );
  static getCustomersFilteredFail = createAction(
    '[RateQuoteActions] Get Customers filtered Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getMatchCustomer = createAction(
    '[RateQuoteActions] Get Match customer',
    props<{ customerData: RateQuoteSearchCustomerSpecificForApi }>()
  );
  static getMatchCustomerSuccess = createAction(
    '[RateQuoteActions] Get Match customer Success',
    props<{ customers: CustomerMatch[] }>()
  );
  static getMatchCustomerFail = createAction(
    '[RateQuoteActions] Get Match customer Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getMatchCustomerByMadCode = createAction(
    '[RateQuoteActions] Get Match customer By MadCode',
    props<{ customerData: RateQuoteSearchCustomerSpecificMadCdForApi }>()
  );
  static getMatchCustomerByMadCodeSuccess = createAction(
    '[RateQuoteActions] Get Match customer By MadCode Success',
    props<{ customers: CustomerMatch[] }>()
  );
  static getMatchCustomerByMadCodeFail = createAction(
    '[RateQuoteActions] Get Match customer By MadCode Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getCustomerLocation = createAction(
    '[RateQuoteActions] Get Customer Location',
    props<{ getCustomerLocationQuery: GetCustomerLocationQuery }>()
  );
  static getCustomerLocationSuccess = createAction(
    '[RateQuoteActions] Get Customer Location Success',
    props<{ customerLocationResp: GetCustomerLocationResp }>()
  );
  static getCustomerLocationFail = createAction(
    '[RateQuoteActions] Get Customer Location Fail',
    props<{ errors: ErrorModel[] }>()
  );
  static setSelectedCustomers = createAction(
    '[RateQuoteActions] Set Selected Customers',
    props<{ selectedCustomer: CustomerMatch }>()
  );
  static getSelectedCustomers = createAction('[RateQuoteActions] Get Selected Customers');

  static setReactiveFieldMessages = createAction(
    '[RateQuoteActions] Set Reactive Field Message',
    props<{ fieldName: AddQuoteAccesorialsType; message: string }>()
  );

  static deleteReactiveFieldMessages = createAction(
    '[RateQuoteActions] Delete Reactive Field Message',
    props<{ fieldName: AddQuoteAccesorialsType; message: string }>()
  );

  static setQuoteSelectedType = createAction(
    '[RateQuoteActions] Set Quote Selected Type',
    props<{ quoteSelectedType: RateQuoteSearchTabsLabel }>()
  );

  static setSelectedFormZoneType = createAction(
    '[RateQuoteActions] Set The Selected Quote Form Zone Type',
    props<{ selectedFormZoneType: countriesCdEnum }>()
  );

  static determineRestrictedBillTo = createAction(
    '[RateQuoteActions] Determine Restricted Account',
    props<{ customerNbr: number }>()
  );

  static determineRestrictedBillToSuccess = createAction(
    '[RateQuoteActions] Determine Restricted Account Success',
    props<{ isRestrictedAccount: boolean }>()
  );

  static determineRestrictedBillToFail = createAction(
    '[RateQuoteActions] Determine Restricted Account Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static recalculateRateQuote = createAction(
    '[RateQuoteActions] Recalculate Quote',
    props<{ recalculateRateQuoteTypes: RecalculateRateQuoteTypes }>()
  );

  static cleanRecalculateRateQuote = createAction(
    '[RateQuoteActions] Clean Recalculate Quote State'
  );

  static getRateQuoteDetail = createAction('[RateQuoteActions] Get Rate Quote Detail', props<{ cnfmNbr: number }>());

  static getRateQuoteDetailSuccess = createAction(
    '[RateQuoteActions] Get Rate Quote Detail Success',
    props<{ rateQuoteDetail: GetRateQuoteResp }>()
  );

  static getRateQuoteDetailFail = createAction(
    '[RateQuoteActions] Get Rate Quote Detail Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static getRateQuoteDetailFromShipment = createAction(
    '[RateQuoteActions] Get Rate Quote Detail from a Shipment',
    props<{ proNbr: string }>()
  );

  static getRateQuoteDetailFromShipmentSuccess = createAction(
    '[RateQuoteActions] Get Rate Quote Detail from a Shipment Success',
    props<{ rateQuoteDetailFromShipment: GetRateQuoteForShipmentResp }>()
  );

  static getRateQuoteDetailFromShipmentFail = createAction(
    '[RateQuoteActions] Get Rate Quote Detail from a Shipment Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static disableInternationalAccessorials = createAction(
    '[RateQuoteActions] Get International Rate Quote disable accessorials',
    props<{ accessorialCode: string, selected: boolean }>()
  );

  static isMexicoRateQuote = createAction(
    '[RateQuoteActions] Is a Mexico Rate quote',
    props<{ isMexicoRateQuote: boolean }>()
  );

  static getSpecialCustomerAcs = createAction(
    '[RateQuoteActions] Get Special Customer Acs',
    props<{ customerNumber: number, date: string, accessorialCds: string[] }>()
  );
  static getSpecialCustomerAcsSuccess = createAction(
    '[RateQuoteActions] Get Special Customer Acs Success',
    props<{ specialCustomerAcs: SpecialCustomerAcsModel[] }>()
  );
  static getSpecialCustomerAcsFail = createAction(
    '[RateQuoteActions] Get Special Customer Acs Fail',
    props<{ errors: ErrorModel[] }>()
  );
}
