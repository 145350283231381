import { Injectable } from '@angular/core';
import { AccountUrls } from '@shared/enums/account-urls.enum';
import { RatingApiService } from '@xpo-ltl-2.0/sdk-rating';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(
    private ratingApiService: RatingApiService,
    public configManagerService: ConfigManagerService
  ) {}

  getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  getUser(): Observable<User> {
    return this.ratingApiService.loggedInUser();
  }
}
