import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { MrraStatusCdEnum } from '@shared/enums/mrra-status-cd.enum';
import { CreateMassRerateRequestModel } from '@shared/models/create-mass-rerate-request.model';
import { GetMrraListRequestModel } from '@shared/models/get-mrra-list-request.model';
import { CreateAdhocMassRerateRqst } from '@xpo-ltl-2.0/sdk-rating';
import { map, take } from 'rxjs/operators';
import { MrraActions } from '../actions/mrra.actions';
import { MrraSelectors } from '../selectors/mrra.selector';

@Injectable({
  providedIn: 'root',
})
export class MrraHandlerService {
  // ACTION ###########################################################################################

  // Create MRRA Actions ************************************************************************
  createMassRerateSuccess$ = this.actions$.pipe(
    ofType(MrraActions.createMassRerateSuccess),
    map(({ createMassRerateResp }) => createMassRerateResp)
  );

  createMassRerateFail$ = this.actions$.pipe(
    ofType(MrraActions.createMassRerateFail),
    map(({ errors }) => errors)
  );

  // List MRRA Actions ************************************************************************
  listMassRerateRequestsSuccess$ = this.actions$.pipe(
    ofType(MrraActions.listMassRerateSuccess),
    map(({ mrraList }) => mrraList)
  );

  listMassRerateRequestsFail$ = this.actions$.pipe(
    ofType(MrraActions.listMassRerateFail),
    map(({ errors }) => errors)
  );

  // Create NEW MRRA Actions ************************************************************************
  createNewMassRerateSuccess$ = this.actions$.pipe(
    ofType(MrraActions.createNewMassRerateRequestSuccess),
    map(({ createdNewMassRerate }) => createdNewMassRerate)
  );

  createNewMassRerateFail$ = this.actions$.pipe(
    ofType(MrraActions.createNewMassRerateRequestFail),
    map(({ errors }) => errors)
  );

  // SELECTORS ************************************************************************
  getGridFilterSelector$ = this.store$.pipe(select(MrraSelectors.getGridFilter));

  constructor(private store$: Store, private actions$: Actions) { }

  clearMrraStore(): void {
    this.store$.dispatch(MrraActions.clearMrraStore());
  }

  createMassRerate(request: CreateAdhocMassRerateRqst): void {
    this.store$.dispatch(MrraActions.createMassRerate({ request }))
  }

  listMassRerateRequests(request: GetMrraListRequestModel): void {
    this.store$.dispatch(MrraActions.listMassRerate(({ request })))
  }

  createNewMassRerate(createMassRerateRequest: CreateMassRerateRequestModel): void {
    this.store$.dispatch(MrraActions.createNewMassRerateRequest({ createMassRerateRequest }))
  }

  setGridStatusCdFilters(statusCdFilters: MrraStatusCdEnum[]): void {
    let storedFilters = {};
    this.getGridFilterSelector$.pipe(take(1)).subscribe(
      filts => {
        if (!filts) return;
        storedFilters = filts;
      }
    );

    const filters = {
      ...storedFilters,
      statusCds: statusCdFilters
    };

    this.store$.dispatch(MrraActions.setGridFilters({ filters }));
  }
}
