import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {
  XpoBusyLoaderModule,
  XpoButtonModule,
  XpoCheckboxModule,
  XpoConfirmDialogModule,
  XpoDialogModule,
  XpoErrorPagesModule,
  XpoIconModule,
  XpoSelectModule,
  XpoTrayModule,
} from '@xpo-ltl/ngx-ltl-core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccountFormFieldComponent } from './components/form-fields/account-form-field/account-form-field.component';
import { AgreementFormFieldComponent } from './components/form-fields/agreement-form-field/agreement-form-field.component';
import { TariffFormFieldComponent } from './components/form-fields/tariff-form-field/tariff-form-field.component';
import { RequestAccountAutocompletePipe } from './pipes/request-account-autocomplete/request-account-autocomplete.pipe';
import { TariffInfoDatesPipe } from './pipes/tariff/tariff-info-dates.pipe';
import { LinkButtonComponent } from './ui/buttons/link-button/link-button.component';
import { ContentPaginationComponent } from './ui/content-pagination/content-pagination.component';
import { InfoDialogComponent } from './ui/dialog/info-dialog/info-dialog.component';
import { ReleaseNotesComponent } from './ui/dialog/release-notes/release-notes.component';
import { SendEmailDialogComponent } from './ui/dialog/send-email-dialog/send-email-dialog.component';
import { ErrorComponent } from './ui/error/error.component';
import { GridLoaderComponent } from './ui/grid-components/grid-loader/grid-loader.component';
import { PdfViewerGeneralComponent } from './ui/pdf/pdf-viewer/pdf-viewer.component';
import { RadioButtonComponent } from './ui/radio-button/radio-button.component';
import { SelectHeaderComponent } from './ui/select/select-header/select-header.component';
import { MaintenancePageComponent } from './ui/special-pages/maintenance-page/maintenance-page.component';
import { TrayComponent } from './ui/tray/tray.component';
import { WarningComponent } from './ui/warning/warning.component';
import { DateXpoPipe } from './pipes/date-xpo/date-xpo.pipe';
import { CommodityPackagesPipe } from './pipes/commodity-packages/commodity-packages.pipe';

@NgModule({
  declarations: [
    TrayComponent,
    SelectHeaderComponent,
    GridLoaderComponent,
    RadioButtonComponent,
    ErrorComponent,
    ContentPaginationComponent,
    ReleaseNotesComponent,
    TariffFormFieldComponent,
    AccountFormFieldComponent,
    RequestAccountAutocompletePipe,
    AgreementFormFieldComponent,
    TariffInfoDatesPipe,
    PdfViewerGeneralComponent,
    SendEmailDialogComponent,
    InfoDialogComponent,
    LinkButtonComponent,
    MaintenancePageComponent,
    WarningComponent,
    DateXpoPipe,
    CommodityPackagesPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    MatRadioModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    XpoTrayModule,
    XpoDialogModule,
    XpoConfirmDialogModule,
    XpoSelectModule,
    XpoBusyLoaderModule,
    XpoButtonModule,
    XpoIconModule,
    XpoCheckboxModule,
    PdfViewerModule,
    XpoErrorPagesModule,
  ],
  providers: [DatePipe],
  exports: [
    TrayComponent,
    SelectHeaderComponent,
    GridLoaderComponent,
    RadioButtonComponent,
    ErrorComponent,
    ContentPaginationComponent,
    ReleaseNotesComponent,
    TariffFormFieldComponent,
    AccountFormFieldComponent,
    RequestAccountAutocompletePipe,
    AgreementFormFieldComponent,
    TariffInfoDatesPipe,
    PdfViewerGeneralComponent,
    SendEmailDialogComponent,
    InfoDialogComponent,
    LinkButtonComponent,
    MaintenancePageComponent,
    WarningComponent,
    DateXpoPipe,
    CommodityPackagesPipe,
  ],
})
export class SharedModule { }
