import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomerApiService } from '@xpo-ltl-2.0/sdk-customer';
import { InfrastructureApiService } from '@xpo-ltl-2.0/sdk-infrastructure';
import { LocationApiService, SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { RatingApiService } from '@xpo-ltl-2.0/sdk-rating';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoPageNotFoundRoutingModule,
  XpoShellModule,
  XpoSnackBarModule,
} from '@xpo-ltl/ngx-ltl-core';
import { CustomerApiService as CustomerV1ApiService } from '@xpo-ltl/sdk-customer';
import { PricingAgmtMgmtApiService } from '@xpo-ltl/sdk-pricingagmtmgmt';
import { TariffManagementApiService } from '@xpo-ltl/sdk-tariffmanagement';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { environment } from '../environments/environment';
import { NotificationComponent } from './app-notification.component';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './app-store/app-store.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, NotificationComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ConfigManagerModule,
    SharedModule,
    AppStoreModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    MatMenuModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    SdkLocationModule,
    SdkUserPreferenceModule,
    XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.RATING }), //make sure SdkLoggingModule is imported after XpoMaintenanceModule for better performance. (https://confluence.xpo.com/display/LA/LTL+Maintenance+UI+Setup)
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
    CoreModule,
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    DataApiService,
    LocationApiService,
    NotificationService,
    RatingApiService,
    TariffManagementApiService,
    PricingAgmtMgmtApiService,
    CustomerApiService,
    CustomerV1ApiService,
    InfrastructureApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
