import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { AccessorialsCdEnum } from '@shared/enums/accessorials-cd.enum';
import { countriesCdEnum } from '@shared/enums/countries-cd.enum';
import { AccessorialField, CreatedRateQuote, RateQuoteSearchTabsLabel } from '@shared/models/rate-quote';
import { SpecialCustomerAcsModel } from '@shared/models/special-customer-acs.model';
import { CustomerMatch } from '@xpo-ltl-2.0/sdk-customer';
import {
  CreateInternationalRateQuoteResp,
  CreateRateQuoteResp,
  CreateSpotQuoteResp,
  GetRateQuoteForShipmentResp,
  GetRateQuoteResp,
  RateQuotePDF,
  RateQuoteRequest
} from '@xpo-ltl-2.0/sdk-rating';
import { ListInfo } from '@xpo-ltl/sdk-common';
import { RateQuoteRules } from 'app/business/rules/rate-quote/rate-quote.rules';
import { CloneDataInDeep } from 'typescript-clone-data-in-deep';
import { RateQuoteActions } from '../actions/rate-quote.actions';

export const rateQuoteFeatureKey = 'rateQuote';
export const rateQuoteFeatureSelector = createFeatureSelector<RateQuoteState>(rateQuoteFeatureKey);

export interface RateQuoteState extends EntityState<RateQuoteRequest> {
  listInfo: ListInfo;
  loadingGetRateQuoteList: boolean;
  loadingGetCustomers: boolean;
  loadingGetRateQuotePdf: boolean;
  loadingCreateRateQuote: boolean;
  loadingCreateRateQuoteUpsell: boolean;
  cancellUpsells: boolean;
  selectedCustomer: CustomerMatch;
  reactiveFieldMessages: { [key: string]: string[] };
  rateQuotePDF: RateQuotePDF;
  createdRateQuote: CreateRateQuoteResp;
  createdSpotQuote: CreateSpotQuoteResp;
  addNewSpotQuoteOptionEnabled: boolean;
  quoteSelectedType: RateQuoteSearchTabsLabel;
  selectedFormZoneType: countriesCdEnum;
  rateQuoteDetail: GetRateQuoteResp;
  rateQuoteDetailFromShipment: GetRateQuoteForShipmentResp;
  isRestrictedAccount: boolean;
  disableInternationalAccessorials: boolean;
  internationalAccessorials: AccessorialField;
  isRecalculateRateQuote: boolean;
  createInternationalRateQuoteResp: CreateInternationalRateQuoteResp;
  isMexicoRateQuote: boolean;
  specialCustomerAcs: SpecialCustomerAcsModel[];
}

export const rateQuoteAdapter: EntityAdapter<RateQuoteRequest> = createEntityAdapter<RateQuoteRequest>({
  selectId: (rateQuote: RateQuoteRequest) => rateQuote.rateQuote.cnfmNbr,
});

export const rateQuoteInitialState: RateQuoteState = rateQuoteAdapter.getInitialState({
  listInfo: undefined,
  loadingGetRateQuoteList: false,
  loadingGetRateQuotePdf: false,
  loadingGetCustomers: false,
  loadingCreateRateQuote: false,
  loadingCreateRateQuoteUpsell: false,
  cancellUpsells: false,
  selectedCustomer: undefined,
  reactiveFieldMessages: undefined,
  rateQuotePDF: undefined,
  createdRateQuote: undefined,
  createdSpotQuote: undefined,
  addNewSpotQuoteOptionEnabled: undefined,
  quoteSelectedType: undefined,
  selectedFormZoneType: undefined,
  rateQuoteDetail: undefined,
  rateQuoteDetailFromShipment: undefined,
  isRestrictedAccount: false,
  disableInternationalAccessorials: false,
  internationalAccessorials: {
    [AccessorialsCdEnum.borderCrossingServiceDelivery]: true
  },
  isRecalculateRateQuote: false,
  createInternationalRateQuoteResp: undefined,
  isMexicoRateQuote: false,
  specialCustomerAcs: undefined,
});

export const reducer = createReducer(
  rateQuoteInitialState,
  on(RateQuoteActions.clearRateQuoteStore, (state) => {
    return {
      ...rateQuoteInitialState,
    };
  }),
  on(RateQuoteActions.getRateQuoteList, (state) => {
    return {
      ...state,
      loadingGetRateQuoteList: true,
      listInfo: undefined,
    };
  }),
  on(RateQuoteActions.getRateQuoteListSuccess, (state, { rateQuoteList }) => {
    return rateQuoteAdapter.addMany(rateQuoteList?.rateQuoteRequests || [], {
      ...state,
      loadingGetRateQuoteList: false,
      listInfo: rateQuoteList.listInfo,
    });
  }),
  on(RateQuoteActions.getRateQuoteListFail, (state) => {
    return {
      ...state,
      loadingGetRateQuoteList: false,
    };
  }),
  on(RateQuoteActions.getRateQuotePdf, (state) => {
    return {
      ...state,
      loadingGetRateQuotePdf: true,
    };
  }),
  on(RateQuoteActions.getRateQuotePdfSuccess, (state, { rateQuoteFileResp }) => {
    return {
      ...state,
      loadingGetRateQuotePdf: false,
      rateQuotePDF: rateQuoteFileResp.rateQuotePDF,
    };
  }),
  on(RateQuoteActions.getRateQuotePdfFail, (state) => {
    return {
      ...state,
      loadingGetRateQuotePdf: false,
    };
  }),
  on(RateQuoteActions.clearRateQuoteList, (state) => {
    return rateQuoteAdapter.removeAll({
      ...state,
      listInfo: undefined,
    });
  }),
  on(RateQuoteActions.getCustomersFiltered, (state) => {
    return {
      ...state,
      loadingGetCustomers: true,
    };
  }),
  on(RateQuoteActions.getCustomersFilteredSuccess, RateQuoteActions.getCustomersFilteredFail, (state) => {
    return {
      ...state,
      loadingGetCustomers: false,
    };
  }),
  on(RateQuoteActions.setSelectedCustomers, (state, { selectedCustomer }) => {
    return {
      ...state,
      selectedCustomer,
    };
  }),
  on(RateQuoteActions.setReactiveFieldMessages, (state, { fieldName, message }) => {
    if (!fieldName) {
      return state;
    }

    const reactiveFieldMessages: { [key: string]: string[] } = CloneDataInDeep.clone(state.reactiveFieldMessages) || {};

    if (!reactiveFieldMessages[fieldName]) {
      reactiveFieldMessages[fieldName] = [];
    }

    reactiveFieldMessages[fieldName].push(message);

    return {
      ...state,
      reactiveFieldMessages,
    };
  }),
  on(RateQuoteActions.deleteReactiveFieldMessages, (state, { fieldName, message }) => {
    if (!fieldName) {
      return state;
    }

    const reactiveFieldMessages: { [key: string]: string[] } = CloneDataInDeep.clone(state.reactiveFieldMessages) || {};

    if (!reactiveFieldMessages[fieldName]) {
      return state;
    }

    const index = reactiveFieldMessages[fieldName].indexOf(message);
    if (index !== -1) {
      reactiveFieldMessages[fieldName].splice(index, 1);
    }

    return {
      ...state,
      reactiveFieldMessages,
    };
  }),
  on(RateQuoteActions.createRateQuote, (state) => {
    return {
      ...state,
      loadingCreateRateQuote: true,
    };
  }),
  on(RateQuoteActions.createRateQuoteSucess, (state, { createRateQuoteResp }) => {
    return {
      ...state,
      createdRateQuote: createRateQuoteResp,
      createdSpotQuote: undefined,
      loadingCreateRateQuote: false
    };
  }),
  on(RateQuoteActions.createRateQuoteFail, (state) => {
    return {
      ...state,
      createdRateQuote: undefined,
      createdSpotQuote: undefined,
      loadingCreateRateQuote: false
    };
  }),
  on(RateQuoteActions.createRateQuoteUpsell, (state) => {
    return {
      ...state,
      loadingCreateRateQuoteUpsell: true,
      cancellUpsells: false
    };
  }),
  on(RateQuoteActions.createRateQuoteUpsellSucess, RateQuoteActions.createRateQuoteUpsellFail, (state) => {
    return {
      ...state,
      loadingCreateRateQuoteUpsell: false
    };
  }),
  on(RateQuoteActions.setQuoteSelectedType, (state, { quoteSelectedType }) => {
    return {
      ...state,
      quoteSelectedType,
    };
  }),
  on(RateQuoteActions.setSelectedFormZoneType, (state, { selectedFormZoneType }) => {
    return {
      ...state,
      selectedFormZoneType,
    };
  }),
  on(RateQuoteActions.getRateQuoteDetail, (state) => {
    return {
      ...state,
      rateQuoteDetail: undefined,
    };
  }),
  on(RateQuoteActions.getRateQuoteDetailSuccess, (state, { rateQuoteDetail }) => {
    return {
      ...state,
      rateQuoteDetail,
    };
  }),
  on(RateQuoteActions.getRateQuoteDetailFromShipment, (state) => {
    return {
      ...state,
      rateQuoteDetailFromShipment: undefined,
    };
  }),
  on(RateQuoteActions.getRateQuoteDetailFromShipmentSuccess, (state, { rateQuoteDetailFromShipment }) => {
    return {
      ...state,
      rateQuoteDetailFromShipment,
    };
  }),
  on(RateQuoteActions.determineRestrictedBillTo, (state) => {
    return {
      ...state,
      isRestrictedAccount: false,
    };
  }),
  on(RateQuoteActions.determineRestrictedBillToSuccess, (state, { isRestrictedAccount }) => {
    return {
      ...state,
      isRestrictedAccount,
    };
  }),
  on(RateQuoteActions.createSpotQuote, (state) => {
    return {
      ...state,
      createdRateQuote: undefined,
      createdSpotQuote: undefined,
    };
  }),
  on(RateQuoteActions.createSpotQuoteSuccess, (state, { createSpotQuoteResp }) => {
    return {
      ...state,
      createdSpotQuote: createSpotQuoteResp,
    };
  }),
  on(RateQuoteActions.addNewSpotQuoteOptionEnabled, (state, { addNewSpotQuoteOptionEnabled }) => {
    return {
      ...state,
      addNewSpotQuoteOptionEnabled,
    };
  }),
  on(RateQuoteActions.recalculateRateQuote, (state, { recalculateRateQuoteTypes }) => {
    return {
      ...state,
      isRecalculateRateQuote: recalculateRateQuoteTypes != null,
    };
  }),
  on(RateQuoteActions.cleanRecalculateRateQuote, (state) => {
    return {
      ...state,
      isRecalculateRateQuote: false,
      cancellUpsells: true
    };
  }),
  on(RateQuoteActions.disableInternationalAccessorials, (state, { accessorialCode, selected }) => {
    const internationalAccessorials = { ...state.internationalAccessorials };
    internationalAccessorials[accessorialCode] = selected;

    let selectedAccesorialsNumber = 0;
    for (const accessorialValue of Object.values(internationalAccessorials)) {
      if (accessorialValue) { selectedAccesorialsNumber++; }
    }

    if (selectedAccesorialsNumber >= RateQuoteRules.internationalAccesorialQuantityAllowed) {
      if (!state.disableInternationalAccessorials) {
        return {
          ...state,
          disableInternationalAccessorials: true,
          internationalAccessorials
        }
      }

    } else if (state.disableInternationalAccessorials) {
      return {
        ...state,
        disableInternationalAccessorials: false,
        internationalAccessorials
      }
    }

    return {
      ...state,
      internationalAccessorials
    }
  }),
  on(RateQuoteActions.createInternationalRateQuote, (state) => {
    return {
      ...state,
      createInternationalRateQuoteResp: undefined,
    };
  }),
  on(RateQuoteActions.createInternationalRateQuoteSucess, (state, { createInternationalRateQuoteResp }) => {
    return {
      ...state,
      createInternationalRateQuoteResp,
    };
  }),
  on(RateQuoteActions.isMexicoRateQuote, (state, { isMexicoRateQuote }) => {
    return {
      ...state,
      isMexicoRateQuote,
    };
  }),
  on(RateQuoteActions.getSpecialCustomerAcs, (state) => {
    return {
      ...state,
      specialCustomerAcs: undefined,
    };
  }),
  on(RateQuoteActions.getSpecialCustomerAcsSuccess, (state, { specialCustomerAcs }) => {
    return {
      ...state,
      specialCustomerAcs,
    };
  }),
);
