import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { countriesCdEnum } from '@shared/enums/countries-cd.enum';
import {
  RateQuoteCallGetListAttributtes,
  RateQuoteSearchCustomerSpecificForApi,
  RateQuoteSearchCustomerSpecificMadCdForApi,
  RateQuoteSearchTabsLabel,
  RecalculateRateQuoteTypes
} from '@shared/models/rate-quote';
import { CustomerMatch, GetCustomerLocationQuery } from '@xpo-ltl-2.0/sdk-customer';
import { CreateInternationalRateQuoteRqst, CreateRateQuoteRqst, CreateSpotQuoteRqst } from '@xpo-ltl-2.0/sdk-rating';
import { DataOptions } from '@xpo-ltl/data-api';
import { AddQuoteAccesorialsType } from 'app/rate-quote/add-rate-quote/shared/form-utils/add-quote-form.config';
import { filter, map } from 'rxjs/operators';
import { RateQuoteActions } from '../actions/rate-quote.actions';
import { RateQuoteSelectors } from '../selectors/rate-quote.selectors';

@Injectable({
  providedIn: 'root',
})
export class RateQuoteHandlerService {
  // ACTION ###########################################################################################

  // Get Rate Quote List Actions // Customer Actions **************************************************
  getRateQuoteListSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.getRateQuoteListSuccess),
    map((action) => ({ rateQuoteList: action.rateQuoteList, observableId: action.observableId }))
  );

  getRateQuoteListFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.getRateQuoteListFail),
    map((action) => action.errors)
  );

  // Create Rate Quote Actions ************************************************************************
  createRateQuoteSucess$ = this.actions$.pipe(
    ofType(RateQuoteActions.createRateQuoteSucess),
    map(({ createRateQuoteResp }) => createRateQuoteResp)
  );

  createRateQuoteFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.createRateQuoteFail),
    map(({ errors }) => errors)
  );

  // Create Rate Quote Upsell *************************************************************************
  createRateQuoteUpsellSucess$ = this.actions$.pipe(
    ofType(RateQuoteActions.createRateQuoteUpsellSucess),
    filter(({ createdRateQuote }) => !!createdRateQuote?.createRateQuoteResp?.cnfmNbr),
    map(({ createdRateQuote }) => createdRateQuote)
  );

  createRateQuoteUpsellFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.createRateQuoteUpsellFail),
    map(({ errors }) => errors)
  );

  // Create Spot Quote Actions ************************************************************************
  createSpotQuoteSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.createSpotQuoteSuccess),
    map(({ createSpotQuoteResp }) => createSpotQuoteResp)
  );

  createSpotQuoteFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.createSpotQuoteFail),
    map(({ errors }) => errors)
  );

  // Create International Rate Quote Actions ************************************************************************
  createInternationalRateQuoteSucess$ = this.actions$.pipe(
    ofType(RateQuoteActions.createInternationalRateQuoteSucess),
    map(({ createInternationalRateQuoteResp }) => createInternationalRateQuoteResp)
  );

  createInternationalRateQuoteFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.createInternationalRateQuoteFail),
    map(({ errors }) => errors)
  );

  // Customer Actions *********************************************************************************
  getCustomersFilteredSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.getCustomersFilteredSuccess),
    map((action) => action.customers)
  );

  getCustomersFilteredFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.getCustomersFilteredFail),
    map((action) => action.errors)
  );

  getMatchCustomerSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.getMatchCustomerSuccess),
    map((action) => action.customers)
  );

  getMatchCustomerFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.getMatchCustomerFail),
    map((action) => action.errors)
  );

  getMatchCustomerByMadCodeSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.getMatchCustomerByMadCodeSuccess),
    map((action) => action.customers)
  );

  getMatchCustomerByMadCodeFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.getMatchCustomerByMadCodeFail),
    map((action) => action.errors)
  );

  getCustomerLocationSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.getCustomerLocationSuccess),
    map((action) => action.customerLocationResp)
  );

  getCustomerLocationFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.getCustomerLocationFail),
    map((action) => action.errors)
  );

  // GET Special Customer ACs ************************************************************************
  getSpecialCustomerAcsSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.getSpecialCustomerAcsSuccess),
    map(({ specialCustomerAcs }) => specialCustomerAcs)
  );

  getSpecialCustomerAcsFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.getSpecialCustomerAcsFail),
    map(({ errors }) => errors)
  );

  // ****************************************************

  getRateQuotePdfSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.getRateQuotePdfSuccess),
    map((action) => action.rateQuoteFileResp)
  );

  getRateQuotePdfFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.getRateQuotePdfFail),
    map((action) => action.errors)
  );

  getRateQuoteDetailSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.getRateQuoteDetailSuccess),
    map((action) => action.rateQuoteDetail)
  );

  getRateQuoteDetailFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.getRateQuoteDetailFail),
    map((action) => action.errors)
  );

  getRateQuoteDetailFromShipmentSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.getRateQuoteDetailFromShipmentSuccess),
    map((action) => action.rateQuoteDetailFromShipment)
  );

  getRateQuoteDetailFromShipmentFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.getRateQuoteDetailFromShipmentFail),
    map((action) => action.errors)
  );

  determineRestrictedBillToSuccess$ = this.actions$.pipe(
    ofType(RateQuoteActions.determineRestrictedBillToSuccess),
    map((action) => action.isRestrictedAccount)
  );

  determineRestrictedBillToFail$ = this.actions$.pipe(
    ofType(RateQuoteActions.determineRestrictedBillToFail),
    map((action) => action.errors)
  );

  // RECALCULATE RATE QUOTE ACTIONS
  recalculateRateQuote$ = this.actions$.pipe(
    ofType(RateQuoteActions.recalculateRateQuote),
    map((action) => action.recalculateRateQuoteTypes)
  );

  // SELECTORS #########################################################################################
  rateQuoteListWithListInfoSelector$ = this.store$.pipe(select(RateQuoteSelectors.rateQuoteListWithListInfo));
  loadingGetRateQuoteListSelector$ = this.store$.pipe(select(RateQuoteSelectors.loadingGetRateQuoteList));
  loadingGetRateQuotePdfSelector$ = this.store$.pipe(select(RateQuoteSelectors.loadingGetRateQuotePdf));

  loadingGetCustomersSelector$ = this.store$.pipe(select(RateQuoteSelectors.loadingGetCustomers));
  loadingCreateRateQuote$ = this.store$.pipe(select(RateQuoteSelectors.loadingCreateRateQuote));
  loadingCreateRateQuoteUpsell$ = this.store$.pipe(select(RateQuoteSelectors.loadingCreateRateQuoteUpsell));
  cancellUpsells$ = this.store$.pipe(select(RateQuoteSelectors.cancellUpsells));

  getSelectedCustomersSelector$ = this.store$.pipe(select(RateQuoteSelectors.getSelectedCustomers));

  getReactiveFieldMessagesSelector$ = this.store$.pipe(select(RateQuoteSelectors.getReactiveFieldMessages));

  getResultFileSelector$ = this.store$.pipe(select(RateQuoteSelectors.getResultFile));

  createdRateQuote$ = this.store$.pipe(select(RateQuoteSelectors.createdRateQuote));

  createdSpotQuote$ = this.store$.pipe(select(RateQuoteSelectors.createdSpotQuote));
  addNewSpotQuoteOption$ = this.store$.pipe(select(RateQuoteSelectors.addNewSpotQuoteOption));

  quoteSelectedTypeSelector$ = this.store$.pipe(select(RateQuoteSelectors.quoteSelectedType));

  getSelectedFormZoneType$ = this.store$.pipe(select(RateQuoteSelectors.getSelectedFormZoneType));

  getRateQuoteDetailSelector$ = this.store$.pipe(select(RateQuoteSelectors.getRateQuoteDetail));

  getRateQuoteDetailFromShipmentSelector$ = this.store$.pipe(select(RateQuoteSelectors.getRateQuoteDetailFromShipment));

  isRestrictedAccountSelector$ = this.store$.pipe(select(RateQuoteSelectors.isRestrictedAccount));

  disableInternationalAccessorials$ = this.store$.pipe(select(RateQuoteSelectors.disableInternationalAccessorials));

  isRecalculateRateQuote$ = this.store$.pipe(select(RateQuoteSelectors.isRecalculateRateQuote));
  
  createInternationalRateQuoteResp$ = this.store$.pipe(select(RateQuoteSelectors.createInternationalRateQuoteResp));
  
  isMexicoRateQuote$ = this.store$.pipe(select(RateQuoteSelectors.isMexicoRateQuote));
  
  specialCustomerAcs$ = this.store$.pipe(select(RateQuoteSelectors.specialCustomerAcs));
  
  getSpecialCustomerMabdAc$ = this.store$.pipe(select(RateQuoteSelectors.getSpecialCustomerMabdAc));

  constructor(private store$: Store, private actions$: Actions) { }

  clearRateQuoteStore(): void {
    this.store$.dispatch(RateQuoteActions.clearRateQuoteStore());
  }

  getRateQuoteList(rateQuoteAttributtes: RateQuoteCallGetListAttributtes): void {
    this.store$.dispatch(RateQuoteActions.getRateQuoteList({ rateQuoteAttributtes }));
  }

  clearRateQuoteList(): void {
    this.store$.dispatch(RateQuoteActions.clearRateQuoteList());
  }

  createRateQuote(request: CreateRateQuoteRqst): void {
    this.store$.dispatch(RateQuoteActions.createRateQuote({ request }));
  }

  createRateQuoteUpsell(request: CreateRateQuoteRqst, recalculateRateQuoteTypes?: RecalculateRateQuoteTypes, dataOptions?: DataOptions): void {
    this.store$.dispatch(RateQuoteActions.createRateQuoteUpsell({ request, recalculateRateQuoteTypes, dataOptions }));
  }

  createSpotQuote(request: CreateSpotQuoteRqst): void {
    this.store$.dispatch(RateQuoteActions.createSpotQuote({ request }));
  }

  addNewSpotQuoteOptionEnabled(enable: boolean): void {
    this.store$.dispatch(RateQuoteActions.addNewSpotQuoteOptionEnabled({ addNewSpotQuoteOptionEnabled: enable }));
  }

  createInternationalRateQuote(request: CreateInternationalRateQuoteRqst): void {
    this.store$.dispatch(RateQuoteActions.createInternationalRateQuote({ request }));
  }

  getCustomers(filterData: string): void {
    this.store$.dispatch(RateQuoteActions.getCustomersFiltered({ filterData }));
  }

  getMatchCustomer(customerData: RateQuoteSearchCustomerSpecificForApi): void {
    this.store$.dispatch(RateQuoteActions.getMatchCustomer({ customerData }));
  }

  getMatchCustomerByMadCode(customerData: RateQuoteSearchCustomerSpecificMadCdForApi): void {
    this.store$.dispatch(RateQuoteActions.getMatchCustomerByMadCode({ customerData }));
  }

  setSelectedCustomer(selectedCustomer: CustomerMatch): void {
    this.store$.dispatch(RateQuoteActions.setSelectedCustomers({ selectedCustomer }));
  }

  getRateQuotePdf(cnfmNbr: string, serviceDataOptions?: DataOptions, retryApiCall: boolean = false): void {
    this.store$.dispatch(RateQuoteActions.getRateQuotePdf({ cnfmNbr, serviceDataOptions, retryApiCall }));
  }

  getRateQuoteDetail(cnfmNbr: number): void {
    this.store$.dispatch(RateQuoteActions.getRateQuoteDetail({ cnfmNbr }));
  }

  getRateQuoteDetailFromShipment(proNbr: string): void {
    this.store$.dispatch(RateQuoteActions.getRateQuoteDetailFromShipment({ proNbr }));
  }

  setReactiveFieldMessages(fieldName: AddQuoteAccesorialsType, message: string): void {
    this.store$.dispatch(RateQuoteActions.setReactiveFieldMessages({ fieldName, message }));
  }

  deleteReactiveFieldMessages(fieldName: AddQuoteAccesorialsType, message: string): void {
    this.store$.dispatch(RateQuoteActions.deleteReactiveFieldMessages({ fieldName, message }));
  }

  setQuoteSelectedType(quoteSelectedType: RateQuoteSearchTabsLabel): void {
    this.store$.dispatch(RateQuoteActions.setQuoteSelectedType({ quoteSelectedType }));
  }

  setSelectedFormZoneType(selectedFormZoneType: countriesCdEnum): void {
    this.store$.dispatch(RateQuoteActions.setSelectedFormZoneType({ selectedFormZoneType }));
  }

  determineRestrictedBillTo(customerNbr: number): void {
    this.store$.dispatch(RateQuoteActions.determineRestrictedBillTo({ customerNbr }));
  }

  recalculateRateQuote(recalculateRateQuoteTypes: RecalculateRateQuoteTypes): void {
    this.store$.dispatch(RateQuoteActions.recalculateRateQuote({ recalculateRateQuoteTypes }));
  }

  cleanRecalculateRateQuote(): void {
    this.store$.dispatch(RateQuoteActions.cleanRecalculateRateQuote());
  }

  getCustomerLocation(getCustomerLocationQuery: GetCustomerLocationQuery): void {
    this.store$.dispatch(RateQuoteActions.getCustomerLocation({ getCustomerLocationQuery }));
  }

  disableInternationalAccessorials(accessorialCode: string, selected: boolean): void {
    this.store$.dispatch(RateQuoteActions.disableInternationalAccessorials({ accessorialCode, selected }));
  }

  isMexicoRateQuote(isMexicoRateQuote: boolean): void {
    this.store$.dispatch(RateQuoteActions.isMexicoRateQuote({ isMexicoRateQuote }));
  }

  getSpecialCustomerAcs(params: {customerNumber: number, date?: string, accessorialCds: string[]}): void {
    const { customerNumber, date, accessorialCds } = params;
    this.store$.dispatch(RateQuoteActions.getSpecialCustomerAcs({ customerNumber, date, accessorialCds }));
  }
}
