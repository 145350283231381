import { Injectable } from '@angular/core';
import { LocationApiMapperService } from '@core/mappers/location/api/location-api-mapper.service';
import { LocationService } from '@core/services/location/location.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorModel } from '@shared/models/error';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LocationActions } from '../actions/location.actions';

@Injectable()
export class LocationEffects {
  getSicForPostalCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocationActions.getSicForPostalCodes),
      mergeMap(({ location }) => {
        const request = this.locationApiMapperService.getRequest(location);

        return forkJoin(
          request.usCa ? this.locationService.getSicForPostalCodes(request.usCa) : of(undefined),
          // EAM-136: TODO: Enable the Mexico postal code validation again when we have data base data, for now only the tariff api will check it
          // request.mx ? this.locationService.getSicForPostalCodes(request.mx) : of(undefined)
          of(undefined)
        ).pipe(
          map(([locationUsCaResp, locationMxResp]) => {
            const locationResponse = this.locationApiMapperService.getResponse(locationUsCaResp, locationMxResp);
            return LocationActions.getSicForPostalCodesSuccess({ locationResponse })
          }
          ),
          catchError((errors: ErrorModel[]) => of(LocationActions.getSicForPostalCodesFail({ errors })))
        )
      })
    )
  );

  constructor(
    private actions$: Actions,
    private locationService: LocationService,
    private locationApiMapperService: LocationApiMapperService) { }
}
