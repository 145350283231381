import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/error';
import { LocationModel, LocationResponseModel } from '@shared/models/location.model';

export abstract class LocationActions {
  static getSicForPostalCodes = createAction(
    '[LocationActions] Get information for postal codes',
    props<{ location: LocationModel }>()
  );
  static getSicForPostalCodesSuccess = createAction(
    '[LocationActions] Get information for postal codes Success',
    props<{ locationResponse: LocationResponseModel }>()
  );
  static getSicForPostalCodesFail = createAction(
    '[LocationActions] Get information for postal codes Fail',
    props<{ errors: ErrorModel[] }>()
  );
}
