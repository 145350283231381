import { countriesCdEnum } from "@shared/enums/countries-cd.enum";
import { GetSicForPostalCodesQuery, PostalSicAssignment } from "@xpo-ltl-2.0/sdk-location";
import { DataValidationError } from "@xpo-ltl/sdk-common";

export interface LocationSucessModel {
  postalSicAssignments: PostalSicAssignment[];
  validationErrors: DataValidationError;
};

export interface GetSicForLocationModel {
  postalCode: string;
  countryCode?: countriesCdEnum;
};

export class LocationModel {
  originLocation?: GetSicForLocationModel;
  destLocation?: GetSicForLocationModel;
};

export class LocationGetQueryModel {
  usCa: GetSicForPostalCodesQuery;
  mx: GetSicForPostalCodesQuery;
};

export class LocationResponseModel {
  postalSicAssignments: PostalSicAssignment[];
  validationErrors: DataValidationError[]
};
