import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { CreateMassRerateModel } from '@shared/models/create-mass-rerate.model';
import { MrraGridFiltersModel } from '@shared/models/mrra-grid-filters.model';
import { MrraListModel } from '@shared/models/mrra-list.model';
import { CreateAdhocMassRerateResp } from '@xpo-ltl-2.0/sdk-rating';
import { MrraActions } from '../actions/mrra.actions';

export const mrraFeatureKey = 'mrra';
export const mrraFeatureSelector = createFeatureSelector<MrraState>(mrraFeatureKey);

export interface MrraState {
  createdMassRerate: CreateAdhocMassRerateResp;
  mrraList: MrraListModel;
  createdNewMassRerate: CreateMassRerateModel;
  gridFilters: MrraGridFiltersModel;
}

export const mrraInitialState: MrraState = {
  createdMassRerate: undefined,
  mrraList: undefined,
  createdNewMassRerate: undefined,
  gridFilters: undefined
};

export const reducer = createReducer(
  mrraInitialState,
  on(MrraActions.clearMrraStore, (state) => {
    return {
      ...mrraInitialState,
    };
  }),
  on(MrraActions.createMassRerate, (state) => {
    return {
      ...state,
      createdMassRerate: undefined
    };
  }),
  on(MrraActions.createMassRerateSuccess, (state, { createMassRerateResp}) => {
    return {
      ...state,
      createdMassRerate: createMassRerateResp,
    };
  }),
  on(MrraActions.listMassRerate, (state) => {
    return {
      ...state,
      mrraList: undefined
    };
  }),
  on(MrraActions.listMassRerateSuccess, (state, { mrraList }) => {
    return {
      ...state,
      mrraList,
    };
  }),
  on(MrraActions.createNewMassRerateRequest, (state) => {
    return {
      ...state,
      createdNewMassRerate: undefined
    };
  }),
  on(MrraActions.createNewMassRerateRequestSuccess, (state, { createdNewMassRerate }) => {
    return {
      ...state,
      createdNewMassRerate,
    };
  }),
  on(MrraActions.setGridFilters, (state, { filters }) => {
    return {
      ...state,
      gridFilters: filters,
    };
  }),
);
