export enum AccessorialsCdEnum {
  insidePickup = 'OIP',
  exhibitionPickup = 'PEO',
  liftGateServicePickup = 'OLG',
  containerStationPickup = 'OCF',
  residentialPickup = 'RSO',
  limitedAccessPickup = 'OLA',
  sortingPickup = 'OSS',
  consolidationPickup = 'HCB',
  inspectionPickup = 'CBI',
  reconsignmentPickup = 'ORC',
  constructionPickup = 'OCS',
  mineGovAirpPickup = 'OMS',
  weighPickup = 'CBR',
  holidayWeekendPickup = 'OSH',
  piersPickup = 'OPW',
  liftGateServiceDelivery = 'DLG',
  sortingDelivery = 'SRT',
  notificationDelivery = 'DNC',
  insideDelivery = 'DID',
  exhibitionDelivery = 'PED',
  residentialDelivery = 'RSD',
  limitedAccessDelivery = 'DLA',
  containerStationDelivery = 'DCF',
  constructionDelivery = 'CSD',
  mineGovAirpDelivery = 'DMS',
  appointmentDelivery = 'APT',
  mustArriveByDate = 'MABD', // This accessorial is not been using to send data to the backend, it is only used to identified the form field, the accessorials that the UI are sending to the API are MBS and MBD
  mustArriveByDateStandard = 'MBS',
  mustArriveByDateDedicated = 'MBD',
  holidayWeekendDelivery = 'DSH',
  afterBusinessHoursDelivery = 'DAH',
  removalPalletsDebris = 'RPD',
  consolidationDelivery = 'HCC',
  reconsignmentDelivery = 'RCD',
  redeliveryDelivery = 'RED',
  piersDelivery = 'DPW',
  borderCrossingServiceDelivery = 'DRA',
  inBondFreight = 'CIB',
  inBondTirCarnet = 'IBC',
  inBondCustomsInspectionDelay = 'IBI',
  inBondCustomsRelForms = 'WAA',
  sufferanceNbOnQc = 'SWC',
  sufferanceOther = 'SWR',
  sufferanceRemanifest = 'SWD',
  sufferanceHandling = 'SWH',
  sufferanceExamination = 'SWE',
  sufferanceInspectionDelay = 'SWP',
  instantGuaranteedService = 'GUR',
  guaranteedByNoon = 'G12',
  blindShipment = 'BLS',
  correctiontoBol = 'CBL',
  codCancelChange = 'CCD',
  returnUndeliveredShipment = 'RET',
  returnCheck = 'RCF',
  rapidRemoteService = 'RRS',
  excessiveLength = 'ELS',
  excessiveValueCharge = 'EVC',
  freezableProtection = 'XFZ',
};
