import { RegexRules } from 'app/business/utils/regex/regex';
import moment from 'moment';
import 'moment-timezone';

export enum DateFormats {
  monthDayYearDash = 'MM/dd/yyyy',
  monthDayYearDashMoment = 'MM/DD/YYYY',
  monthDayYearHourMinuteSecond = 'MM/DD/YYYY HH:mm:ss',
  monthDayYearHourMinuteDash = 'MM/DD/YYYY HH:mm',
  monthDayYearHourMinuteDashTimeZone = 'MM/DD/YYYY HH:mm z',
  yearMonthDayHypen = 'yyyy-MM-dd',
  yearMonthDayHourMinuteSecondTimezone = 'YYYY-MM-DD HH:mm:ss Z',
  yearMonthDay = 'YYYY-MM-DD',
  defaultApiDateFormat = 'MM-DD-YYYY',
  timestamp = 'MM/DD/YYYY hh:mmA',
}

export enum TimeZoneFormat {
  us = 'en-US',
}

export class DateRatingUtils {
  static timeZone = {
    local: undefined,
    pst: 'America/Los_Angeles',
  };

  static dateNumberToStringWithoutTime = (
    dateNumber: number,
    timeZone = DateRatingUtils.timeZone.local,
    timeZoneFormat = TimeZoneFormat.us
  ): string => {
    return new Date(dateNumber).toLocaleDateString(timeZoneFormat, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone,
    });
  };

  static dateTimeToString = (
    date: string,
    enteredDateFormat = DateFormats.yearMonthDayHourMinuteSecondTimezone,
    transformFormat = DateFormats.monthDayYearHourMinuteDash
  ): string => {
    return moment(date, enteredDateFormat)
      .format(transformFormat)
      .toString();
  };

  static momentToString = (
    date: moment.MomentInput,
    format = DateFormats.defaultApiDateFormat,
    timezone?: string
  ): string => {
    if (!date) {
      return;
    }
    return timezone
      ? moment(date)
          .tz(timezone)
          .format(format)
      : moment(date).format(format);
  };

  static getDateFromString(date: string, format = DateFormats.defaultApiDateFormat): moment.Moment {
    return moment(date, format);
  }

  static getDateFromJulianDate(julianDate: number): Date {
    try {
      if (`${julianDate}`.length !== 7) {
        throw Error(`The date ${julianDate} should have 7 digits`);
      }
      if (`${julianDate}`.startsWith('9999')) {
        return undefined;
      }

      const year = Number(`${julianDate}`.slice(0, 4));
      const dayInYear = Number(`${julianDate}`.slice(4, 7));

      const date = new Date();
      date.setHours(0, 0, 0, 0);
      date.setFullYear(year);
      date.setDate(1);
      date.setMonth(0);
      date.setDate(dayInYear);

      return date;
    } catch (error) {
      console.error('Julian Date Conversion', error);
      return undefined;
    }
  }

  static getDateStringFormatFromJulianDate(julianDate: number, dateFormat: DateFormats): string {
    const date: Date = DateRatingUtils.getDateFromJulianDate(julianDate);
    if (!date) {
      return;
    }

    const year: string = `${date.getFullYear()}`;
    const month: string = ('0' + `${date.getMonth() + 1}`).slice(-2);
    const day: string = ('0' + `${date.getDate()}`).slice(-2);

    switch (dateFormat) {
      case DateFormats.monthDayYearDash:
        return `${month}/${day}/${year}`;
      default:
        return `${year}-${month}-${day}`;
    }
  }

  static fromYearToMonthFormat(date: string): string {
    try {
      if (!new RegExp(RegexRules.dateYearMontDayFormat).test(date)) {
        throw Error(`The date format is invalid, should be yyyy/MM/dd ${date}`);
      }
      const [year, month, day]: string[] = date.split('/');
      return `${month}-${day}-${year}`;
    } catch (error) {
      console.log(error);
    }
  }

  static substractDates(
    fromDate: string,
    toDate: string,
    unitOfTime: moment.unitOfTime.Diff = 'days',
    format = DateFormats.monthDayYearDashMoment
  ): number {
    const from = moment(fromDate, format);
    const to = moment(toDate, format);
    return to.diff(from, unitOfTime);
  }

  static substractMomentDates(
    fromDate: moment.Moment,
    toDate: moment.Moment,
    unitOfTime: moment.unitOfTime.Diff = 'days',
  ): number {
    return toDate.diff(fromDate, unitOfTime);
  }

  static getDateFromTimeStamp(timestamp: number, format = DateFormats.timestamp): string {
    return moment(timestamp).format(format);
  }
}
