import { RateQuoteHandlerService } from '@app-store/rate-quote/handler/rate-quote.handler';
import { AlaskaPostalCode, HawaiiPostalCode, PuertoRicoPostalCode } from '@shared/enums/postal-codes.enum';
import { ErrorModel } from '@shared/models/error';
import {
  RateQuoteManualOffshoreEmailSelectedState,
  RateQuoteManualOffshoreEmailTextToReplace,
  RateQuoteOffshoreText,
  RateQuoteSearchTabsLabel,
  ShipOptionsInterface
} from '@shared/models/rate-quote';
import { postalCodes, SpecialClassValues } from '@shared/models/rating';
import { CustomerMatch } from '@xpo-ltl-2.0/sdk-customer';
import { PostalSicAssignment } from '@xpo-ltl-2.0/sdk-location';
import { CreateRateQuoteResp, ShipmentPickupRequest } from '@xpo-ltl-2.0/sdk-rating';
import { CustomerTypeCd } from '@xpo-ltl/sdk-common';
import { RegexRules } from 'app/business/utils/regex/regex';
import { RateQuoteCustomerRole } from 'app/rate-quote/add-rate-quote/shared/form-utils/add-quote-form.config';
import { take } from 'rxjs/operators';

export abstract class RateQuoteRules {
  static pickupEffectiveShipmentDateMaxMonths = 1;
  static pickupEffectiveShipmentDateMinMonths = 24;
  static invalidEffectiveShipmentDateMaxMessage = `The selected date must be equal or less than ${RateQuoteRules.pickupEffectiveShipmentDateMaxMonths} month from today`;
  static invalidEffectiveShipmentDateMinMessage = `The selected date must be equal or greater than 2 years from today`;
  static pickupEffectiveShipmentDateMaxMonthsGeneric = 1;
  static pickupEffectiveShipmentDateMinMonthsGeneric = 36;
  static invalidEffectiveShipmentDateMaxMessageGeneric = `The selected date must be equal or less than ${RateQuoteRules.pickupEffectiveShipmentDateMaxMonths} month from today`;
  static invalidEffectiveShipmentDateMinMessageGeneric = `The selected date must be equal or greater than 3 years from today`;

  static pickupEffectiveShipmentDateMaxMonthsGenericInternational = RateQuoteRules.pickupEffectiveShipmentDateMaxMonthsGeneric;
  static pickupEffectiveShipmentDateMinMonthsGenericInternational = RateQuoteRules.pickupEffectiveShipmentDateMinMonthsGeneric;
  static invalidEffectiveShipmentDateMaxMessageGenericInternational = `The selected date must be equal or less than ${RateQuoteRules.pickupEffectiveShipmentDateMaxMonthsGenericInternational} month from today`;
  static invalidEffectiveShipmentDateMinMessageGenericInternational = `The selected date must be equal or greater than 3 years from today`;
  static pickupEffectiveShipmentDateMaxMonthsCustomerInternational = RateQuoteRules.pickupEffectiveShipmentDateMaxMonths;
  static pickupEffectiveShipmentDateMinMonthsCustomerInternational = RateQuoteRules.pickupEffectiveShipmentDateMinMonths;
  static invalidEffectiveShipmentDateMaxMessageCustomerInternational = `The selected date must be equal or less than ${RateQuoteRules.pickupEffectiveShipmentDateMaxMonthsCustomerInternational} month from today`;
  static invalidEffectiveShipmentDateMinMessageCustomerInternational = `The selected date must be equal or greater than 2 years from today`;
  static stillInProgressApiResponseMessage = 'still in process';

  static discountLevelMaxValue = 99.9;

  static internationalAccesorialQuantityAllowed = 10;

  static maxLengthLongValue = 18;

  static maxDifferenceForCreatedDateFilterInDays = 90;
  static totalCommodityWeightMaxAllowedValue = 100000;
  static totalInternationalCommodityWeightMaxAllowedValue = 100000;
  static maxWeightG12 = 19999;
  static maxWeightInstantGuaranteed = 19999;
  static maxEmailsNumberToSendFile = 5;

  static commodityFieldsWarningValues = {
    weight: { max: 25000, min: 500, minHeavyFreight: 4500 },
    heightTariffWarning: { min: 75 }
  };

  static internationalCommodityFieldsWarningValues = {
    weight: { minHeavyFreightLbs: 4500, minHeavyFreightKg: 2041 }
  };

  static commodityFieldsFormat = {
    weight: { max: 99999, min: 1, pattern: RegexRules.integer },
    length: { max: 320, min: 1, pattern: RegexRules.numbersWithTwoDecimals },
    width: { max: 94.5, min: 1, pattern: RegexRules.numbersWithTwoDecimals },
    height: { max: 98, min: 1, pattern: RegexRules.numbersWithTwoDecimals },
    numberOfUnits: { max: 99999, min: 1, pattern: RegexRules.integer },
    totalPalletCount: { max: 9999, min: 0, pattern: RegexRules.integer },
    totalLinealFeet: { max: 99, min: 0, pattern: RegexRules.integer },
    volume: { max: 99999, min: 0.01, pattern: RegexRules.numbersWithTwoDecimals },
  };

  static commodityList = {
    maxQuantityOfRows: 10,
    defaultQuantityOfRows: 5,
  };

  static competitiveMarketCharges = {
    max: 999999,
    min: 1,
    pattern: RegexRules.numbersWithDecimals,
  };

  static griEffectiveDate = {
    maxDaysAllowedValue: 7,
    minDaysAllowedValue: -7,
  };

  static isCustomerValid(customer: CustomerMatch): boolean {
    return (
      customer.customerTypeCd === CustomerTypeCd.PICKUP_DELIVERY || customer.customerTypeCd === CustomerTypeCd.BILL_TO
    );
  }

  static getCustomerRoleDefaultValue(customer: CustomerMatch): string | number | boolean {
    return RateQuoteRules.isCustomerTypeBillTo(customer)
      ? RateQuoteCustomerRole.thirdParty.text
      : RateQuoteCustomerRole.shipper.value;
  }

  static isCustomerTypeBillTo(customer: CustomerMatch): boolean {
    return customer?.customerTypeCd === CustomerTypeCd.BILL_TO;
  }

  static getDefaultCreatedDateToGridSearch(): { from: Date; to: Date } {
    const to = new Date();
    const from = new Date();
    to.setHours(0, 0, 0, 0);
    from.setHours(0, 0, 0, 0);
    from.setDate(from.getDate() - RateQuoteRules.maxDifferenceForCreatedDateFilterInDays);
    return { from, to };
  }

  static isTheCreatedDatesDifferenceValidToFilter(days: number): boolean {
    return isNaN(days) ? true : days <= RateQuoteRules.maxDifferenceForCreatedDateFilterInDays;
  }

  static getRateQuoteResultFileName(cnfmNbr: number | string): string {
    return `rate-quote-${cnfmNbr}.pdf`;
  }

  static getInternationalRateQuoteResultFileName(): string {
    return `international-rate-quote.pdf`;
  }

  static isAddRateQuoteCommodityRowEmpty(weight: number | string): boolean {
    return weight == null || weight === '';
  }

  static isAddRateQuoteInternationalCommodityRowEmpty(weight: number | string, volume: number | string): boolean {
    return (weight == null || weight === '') && (volume == null || volume === '');
  }

  static classConversor(classValue: string): string {
    return SpecialClassValues[classValue] || classValue;
  }

  static areThereCommodityDimensionValues(length: number): boolean {
    return !!length;
  }

  static isCreatedRateQuoteValid(createRateQuoteResp: CreateRateQuoteResp): boolean {
    return !!createRateQuoteResp.cnfmNbr;
  }

  // POSTAL CODE RULES ****************************************************************************

  static isAlaskaPostalCode(postalCd: string): boolean {
    const numberPostalCd: number = Number(postalCd?.trim());
    return numberPostalCd >= AlaskaPostalCode.lowerLimit && numberPostalCd <= AlaskaPostalCode.upperLimit;
  }

  static isHawaiiPostalCode(postalCd: string): boolean {
    const numberPostalCd: number = Number(postalCd?.trim());
    return numberPostalCd >= HawaiiPostalCode.lowerLimit && numberPostalCd <= HawaiiPostalCode.upperLimit;
  }

  static isPuertoRicoPostalCode(postalCd: string): boolean {
    const numberPostalCd: number = Number(postalCd?.trim());
    return numberPostalCd >= PuertoRicoPostalCode.lowerLimit && numberPostalCd <= PuertoRicoPostalCode.upperLimit;
  }

  // OFFSHORE RULES *******************************************************************************
  static doesManualOffshoreApply(quoteSelectedType: RateQuoteSearchTabsLabel): boolean {
    return !RateQuoteRules.isAnInternationalQuote(quoteSelectedType);
  }

  static sendManualOffshoreBeforeCallCreateApi(shipmentPickupRequest: ShipmentPickupRequest): boolean {
    if (shipmentPickupRequest.originCountryCd !== postalCodes.usCountry.countryCd) {
      return false;
    }
    if (shipmentPickupRequest.originStateCd === postalCodes.usHawaii.stateCoude) {
      return true;
    }

    const destinationOffshore: boolean =
      shipmentPickupRequest.destinationStateCd === postalCodes.usPuertoRico.stateCoude ||
      shipmentPickupRequest.destinationStateCd === postalCodes.usHawaii.stateCoude;
    const originOffshore: boolean =
      shipmentPickupRequest.originStateCd === postalCodes.usPuertoRico.stateCoude ||
      shipmentPickupRequest.originStateCd === postalCodes.usHawaii.stateCoude;

    return destinationOffshore && originOffshore;
  }

  static sendManualOffshoreAfterCallCreateApi(shipmentPickupRequest: ShipmentPickupRequest): boolean {
    if (
      shipmentPickupRequest.originCountryCd !== postalCodes.usCountry.countryCd &&
      shipmentPickupRequest.destinationCountryCd !== postalCodes.usCountry.countryCd
    ) {
      return false;
    }

    const destinationOffshore: boolean =
      shipmentPickupRequest.destinationStateCd === postalCodes.usPuertoRico.stateCoude ||
      shipmentPickupRequest.destinationStateCd === postalCodes.usHawaii.stateCoude ||
      shipmentPickupRequest.destinationStateCd === postalCodes.usAlaska.stateCoude;
    const originOffshore: boolean =
      shipmentPickupRequest.originStateCd === postalCodes.usPuertoRico.stateCoude ||
      shipmentPickupRequest.originStateCd === postalCodes.usHawaii.stateCoude ||
      shipmentPickupRequest.originStateCd === postalCodes.usAlaska.stateCoude;

    return destinationOffshore || originOffshore;
  }

  static getManualOffshoreSelectedState(
    originPostalCode: string,
    destPostalCode: string
  ): RateQuoteManualOffshoreEmailSelectedState {
    if (originPostalCode === postalCodes.usHawaii.stateCoude || destPostalCode === postalCodes.usHawaii.stateCoude) {
      return { isHawaii: true };
    }
    if (
      originPostalCode === postalCodes.usPuertoRico.stateCoude ||
      destPostalCode === postalCodes.usPuertoRico.stateCoude
    ) {
      return { isPuertoRico: true };
    }
    if (originPostalCode === postalCodes.usAlaska.stateCoude || destPostalCode === postalCodes.usAlaska.stateCoude) {
      return { isAlaska: true };
    }
  }

  static getManualOffshoreTitle(
    originPostalCode: string,
    destPostalCode: string,
    textToReturn: RateQuoteOffshoreText,
    textToReplace: RateQuoteManualOffshoreEmailTextToReplace = {
      hawaii: 'Hawaii',
      puertoRico: 'Puerto Rico',
      alaska: 'Alaska',
    }
  ): string {
    const selectedState: RateQuoteManualOffshoreEmailSelectedState = RateQuoteRules.getManualOffshoreSelectedState(
      originPostalCode,
      destPostalCode
    );
    if (selectedState.isHawaii) {
      return textToReturn.replace('{{stateName}}', textToReplace.hawaii);
    }
    if (selectedState.isPuertoRico) {
      return textToReturn.replace('{{stateName}}', textToReplace.puertoRico);
    }
    if (selectedState.isAlaska) {
      return textToReturn.replace('{{stateName}}', textToReplace.alaska);
    }
  }

  static isAdditionalOffshoreInformationNeededForPr(postalSicList: PostalSicAssignment[]): boolean {
    return postalSicList.some(
      (postalSic: PostalSicAssignment) =>
        postalSic.countryCd === postalCodes.usPuertoRico.countryCd &&
        postalSic.stateCd === postalCodes.usPuertoRico.stateCoude
        
    );
  }

  static isAdditionalOffshoreInformationNeededForHawaii(postalSicList: PostalSicAssignment[]): boolean {
    return postalSicList.some(
      (postalSic: PostalSicAssignment) =>
        postalSic.countryCd === postalCodes.usHawaii.countryCd &&
          postalSic.stateCd === postalCodes.usHawaii.stateCoude
        
    );
  }

  static useTransportPostalCode(quoteSelectedType: RateQuoteSearchTabsLabel, customer?: CustomerMatch): boolean {
    if (quoteSelectedType === RateQuoteSearchTabsLabel.genericInternational) {
      return true;
    }
    return customer ? RateQuoteRules.isCustomerTypeBillTo(customer) : false;
  }

  static isAnInternationalQuote(quoteSelectedType: RateQuoteSearchTabsLabel): boolean {
    return (
      quoteSelectedType === RateQuoteSearchTabsLabel.customerInternational ||
      quoteSelectedType === RateQuoteSearchTabsLabel.genericInternational
    );
  }

  static isIntraAlaskaQuote(shipmentPickupRequest: ShipmentPickupRequest): boolean {
    return (
      shipmentPickupRequest.originStateCd === postalCodes.usAlaska.stateCoude &&
      shipmentPickupRequest.destinationStateCd === postalCodes.usAlaska.stateCoude
    );
  }

  static isTheEnteredPostalCodeFromInternationalOrigin(shipOptions: ShipOptionsInterface, shipValue: string): boolean {
    return shipOptions.fromOrigin.value === shipValue;
  }

  static getCustomerName(customer: CustomerMatch): string {
    const name1: string = customer.name1 || '';
    const name2: string = customer.name2 || '';
    return `${name1} ${name2}`.trim();
  }

  static getCustomerNameForApi(customer: CustomerMatch): string {
    const name: string = RateQuoteRules.getCustomerName(customer);
    return name.substring(0, 80);
  }

  static hasStillInProgressApiResponseMessage(errors: ErrorModel[]): boolean {
    return errors.some((error: ErrorModel) => error.message.includes(RateQuoteRules.stillInProgressApiResponseMessage));
  }

  static isMexicoOffshore(shipmentPickupRequest: ShipmentPickupRequest, rateQuoteHandlerService: RateQuoteHandlerService): boolean {
    let isMexico = false;
    rateQuoteHandlerService.isMexicoRateQuote$.pipe(take(1)).subscribe(
      resp => isMexico = resp
    );

    if(!isMexico) return false;

    return (
      shipmentPickupRequest.originStateCd === postalCodes.usAlaska.stateCoude ||
      shipmentPickupRequest.destinationStateCd === postalCodes.usAlaska.stateCoude ||
      shipmentPickupRequest.originStateCd === postalCodes.usPuertoRico.stateCoude ||
      shipmentPickupRequest.destinationStateCd === postalCodes.usPuertoRico.stateCoude ||
      shipmentPickupRequest.originStateCd === postalCodes.usHawaii.stateCoude ||
      shipmentPickupRequest.destinationStateCd === postalCodes.usHawaii.stateCoude
    );
  }
}
