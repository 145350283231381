import { createAction, props } from '@ngrx/store';
import { CreateMassRerateRequestModel } from '@shared/models/create-mass-rerate-request.model';
import { CreateMassRerateModel } from '@shared/models/create-mass-rerate.model';
import { ErrorModel } from '@shared/models/error';
import { GetMrraListRequestModel } from '@shared/models/get-mrra-list-request.model';
import { MrraGridFiltersModel } from '@shared/models/mrra-grid-filters.model';
import { MrraListModel } from '@shared/models/mrra-list.model';
import { CreateAdhocMassRerateResp, CreateAdhocMassRerateRqst } from '@xpo-ltl-2.0/sdk-rating';

export abstract class MrraActions {
  static clearMrraStore = createAction('[MrraActions] Clear Mrra Store');

  static createMassRerate = createAction(
    '[MrraActions] Create an adhoc Mass Rerate Request',
    props<{ request: CreateAdhocMassRerateRqst }>()
  );
  static createMassRerateSuccess = createAction(
    '[MrraActions] Create an adhoc Mass Rerate Request Success',
    props<{ createMassRerateResp: CreateAdhocMassRerateResp }>()
  );
  static createMassRerateFail = createAction(
    '[MrraActions] Create an adhoc Mass Rerate Request Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static listMassRerate = createAction(
    '[MrraActions] List Mass Rerate Requests',
    props<{ request: GetMrraListRequestModel }>()
  );

  static listMassRerateSuccess = createAction(
    '[MrraActions] List Mass Rerate Requests Success',
    props<{ mrraList: MrraListModel}>()
  );

  static listMassRerateFail = createAction(
    '[MrraActions] List Mass Rerate Requests Fail',
    props<{ errors: ErrorModel[] }>()
  );

  static createNewMassRerateRequest = createAction(
    '[MrraActions] Create a new Mass Rerate Request',
    props<{ createMassRerateRequest: CreateMassRerateRequestModel }>()
  );
  static createNewMassRerateRequestSuccess = createAction(
    '[MrraActions] Create a new Mass Rerate Request Success',
    props<{ createdNewMassRerate: CreateMassRerateModel }>()
  );
  static createNewMassRerateRequestFail = createAction(
    '[MrraActions] Create a new Mass Rerate Request Fail',
    props<{ errors: ErrorModel[] }>()
  );
  
  static setGridFilters = createAction(
    '[MrraActions] Set Grid Filters',
    props<{ filters: MrraGridFiltersModel }>()
  );
}
